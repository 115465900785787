export const LOGINPAGE = {
    OTP_VERIFICATION: {
        TITLE: 'Unlock Insights, drive success',
        SUBTITLE: 'Make informed decisions, track key metrics, and drive growth — all in one intuitive analytics dashboard.'
    },
    NEW_USERS: {
        TITLE: 'Join us to create and manage your custom form urls',
        SUBTITLE: 'Start personalizing your form links and simplify how you manage and share your forms.'
    },
    EXISTING_USERS: {
        TITLE: 'Customize your Form URLs for a Seamless Experience',
        SUBTITLE: 'Take control of your form links by creating custom URLs that align with your brand.'
    }
};
