import { useLoginContext } from '@app/context/login-provider';
import { useGetUserStatusQuery } from '@app/store/users/api';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import LoginAuthenticationComponent from './AuthenticationComponent';
import LoginOTPVerificationComponent from './OTPVerification';

const LoginRightPanel = () => {
    const router = useRouter();

    const { loginContext } = useLoginContext();

    const { data: user, isSuccess: isUserStatusSuccess } = useGetUserStatusQuery();

    useEffect(() => {
        if (isUserStatusSuccess && !user?.id) {
            router.push('/login');
        } else if (user?.id) {
            router.push('/dashboard');
        }
    }, [user, isUserStatusSuccess]);

    return <div className="order-1 flex h-full justify-center bg-white px-6 lg:order-2 lg:px-16 xl:px-28 2xl:px-40">{loginContext.stage === 0 ? <LoginAuthenticationComponent /> : <LoginOTPVerificationComponent />}</div>;
};

export default LoginRightPanel;
