import { ArrowLeft } from 'lucide-react';

export const BackButton = ({ handleOnClickBack }: { handleOnClickBack: () => void }) => {
    return (
        <button className="body-14-medium text-sm flex items-center w-fit cursor-pointer flex-row gap-1 rounded-xl border border-gray-200 bg-white px-2 py-[5px] text-gray-900 hover:bg-gray-50 focus:bg-gray-50 active:bg-white" onClick={handleOnClickBack}>
            <ArrowLeft />
            Back
        </button>
    );
};
