import { useBrowserFingerPrintContext } from '@app/context/browser-fingerprint';
import { useLoginContext } from '@app/context/login-provider';
import { Button } from '@app/shadcn/components/ui/button';
import { useToast } from '@app/shadcn/hooks/use-toast';
import { useLazyGoogleLoginQuery, useSendOtpMutation } from '@app/store/users/api';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FormEvent } from 'react';
import { SeperatotWithText } from '../common/SeperatoWithText';
import TextInput from '../common/input/TextInput';

const LoginAuthenticationComponent = () => {
    const router = useRouter();

    const { loginContext, setLoginContext } = useLoginContext();

    const { browserFingerPrint } = useBrowserFingerPrintContext();

    const { toast } = useToast();

    const [trigger] = useLazyGoogleLoginQuery();

    const [sendOtp, { isLoading: isSending, isSuccess: isSendSuccess, isError: isSendError }] = useSendOtpMutation();

    const handleSendCode = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            await sendOtp({ email: loginContext.email }).unwrap();
            handleStageChange(1);
            toast({ title: 'OTP sent successfully!' });
            if (browserFingerPrint) {
                localStorage.setItem('browserFingerPrint', browserFingerPrint);
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast({ title: 'Failed to send OTP. Please try again.', variant: 'destructive' });
        }
    };

    const handleLogin = () => {
        trigger()
            .then((res) => {
                const googleOAuthUrl = res.data.auth_url;
                if (googleOAuthUrl) {
                    router.push(googleOAuthUrl);
                }
                if (browserFingerPrint) {
                    localStorage.setItem('browserFingerPrint', browserFingerPrint);
                }
            })
            .catch((err: any) => console.error('GoogleSignInError : ', err));
    };

    const handleInputChange = (emailVal: string) => {
        setLoginContext({ ...loginContext, email: emailVal });
    };

    const handleStageChange = (stageNumber: number) => {
        setLoginContext({ ...loginContext, stage: stageNumber });
    };

    return (
        <div className="flex h-full w-full flex-col justify-center gap-6">
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-bold text-gray-900 md:text-2xl">{loginContext.isNewUser ? 'Sign Up' : 'Welcome Back!'}</h2>
                <h3 className="text-sm text-gray-500">{loginContext.isNewUser ? 'Join us to create and manage your custom form URLs.' : 'Please sign in to continue'}</h3>
            </div>

            <Button variant="outline" className="flex h-[45px] w-full items-center justify-center rounded-xl text-neutral-800" style={{ backgroundColor: '#F6F6F6' }} onClick={handleLogin}>
                <div className="flex items-center">
                    <Image src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" alt="Google" className="mr-2 h-6 w-6" width={24} height={24} />
                    Sign in with Google
                </div>
            </Button>
            <SeperatotWithText content="OR" />
            <form onSubmit={handleSendCode} className="flex flex-col gap-2">
                <label htmlFor="email" className="text-sm font-medium text-neutral-800">
                    Email
                </label>
                <TextInput id="email" type="email" placeholder="Enter your email address" value={loginContext.email} onChange={(e) => handleInputChange(e.target.value)} />
                <Button className="mt-4" variant={'default'}>
                    {isSending ? 'Sending...' : 'Send Code'}
                </Button>
            </form>
            <div className="flex justify-center gap-1">
                {loginContext.isNewUser ? (
                    <>
                        <h1 className="text-gray-500">Already have an account? </h1>
                        <div className="cursor-pointer text-primary" onClick={() => setLoginContext({ ...loginContext, isNewUser: false })}>
                            Log in
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className="text-gray-500">Don’t have an account?</h1>
                        <div className="cursor-pointer text-primary" onClick={() => setLoginContext({ ...loginContext, isNewUser: true })}>
                            Sign Up
                        </div>
                    </>
                )}
            </div>

            {isSendSuccess && <p className="mt-2 text-green-500">OTP sent successfully!</p>}
            {isSendError && <p className="mt-2 text-red-500">Failed to send OTP. Please try again.</p>}
        </div>
    );
};

export default LoginAuthenticationComponent;
