import LoginLeftPanel from '@app/components/login/LeftPanel';
import LoginRightPanel from '@app/components/login/RightPanel';
import FullScreenLoader from '@app/components/ui/fullscreen-loader';
import { useLoginContext } from '@app/context/login-provider';
import { useEffect, useState } from 'react';

const LoginPage = () => {
    const { loginContext, setLoginContext } = useLoginContext();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!loginContext.redirectFromLandingPage) {
            const existingUser = localStorage.getItem('browserFingerPrint');
            setLoginContext({ ...loginContext, isNewUser: !existingUser });
        }
        setIsLoading(false);
    }, []);

    return (
        <div className="grid h-screen w-full grid-cols-1 p-2 lg:grid-cols-2">
            {isLoading ? (
                <div className="h-full w-screen">
                    <FullScreenLoader />
                </div>
            ) : (
                <>
                    <LoginLeftPanel />
                    <LoginRightPanel />
                </>
            )}
        </div>
    );
};

const LoginPageWithProvider = () => {
    return <LoginPage />;
};

export default LoginPageWithProvider;
