import { LOGINPAGE } from '@app/constants/login';
import { useLoginContext } from '@app/context/login-provider';
import { cn } from '@app/shadcn/util/lib';
import Image from 'next/image';

const LoginLeftPanel = () => {
    const { loginContext } = useLoginContext();

    function getTitle() {
        if (loginContext.stage === 1) {
            return LOGINPAGE.OTP_VERIFICATION.TITLE;
        } else if (loginContext.isNewUser) {
            return LOGINPAGE.NEW_USERS.TITLE;
        } else return LOGINPAGE.EXISTING_USERS.TITLE;
    }

    function getSubTitle() {
        if (loginContext.stage === 1) {
            return LOGINPAGE.OTP_VERIFICATION.SUBTITLE;
        } else if (loginContext.isNewUser) {
            return LOGINPAGE.NEW_USERS.SUBTITLE;
        } else return LOGINPAGE.EXISTING_USERS.SUBTITLE;
    }

    return (
        <div
            className={cn(
                'order-2 h-full w-full basis-1/2 justify-center rounded-3xl bg-cover bg-left-bottom bg-no-repeat p-6 md:p-10 lg:order-1 lg:flex lg:p-16',
                loginContext.stage === 0 ? 'items-center' : '',
                loginContext.stage ? 'bg-verification-page bg-right' : 'bg-authentication-page'
            )}
        >
            <div className={cn('flex w-full flex-col items-center justify-start gap-2 pt-2 text-center text-white md:gap-10 lg:w-[440px]', loginContext.stage === 1 ? 'md:pt-20' : '')}>
                <h1 className="heading-28-bold md:text-5xl md:font-bold">{getTitle()}</h1>
                <h2 className="body-16-regular md:body-20-regular">{getSubTitle()}</h2>
                {loginContext.stage === 0 && <Image src={'/images/login-rating.png'} alt="rating" style={{ width: 'fit-content', height: 'fit-content' }} width={900} height={5} />}
            </div>
        </div>
    );
};

export default LoginLeftPanel;
