import TextInput from '@app/components/common/input/TextInput';
import { useLoginContext } from '@app/context/login-provider';
import { Button } from '@app/shadcn/components/ui/button';
import { useToast } from '@app/shadcn/hooks/use-toast';
import { useVerifyOtpMutation } from '@app/store/users/api';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { BackButton } from '../common/button/BackButton';

const LoginOTPVerificationComponent = () => {
    const router = useRouter();

    const [otp, setOtp] = useState('');

    const { toast } = useToast();

    const { loginContext, setLoginContext } = useLoginContext();

    const [verifyOtp, { isLoading: isVerifying }] = useVerifyOtpMutation();

    const handleVerifyOtp = async () => {
        try {
            await verifyOtp({ email: loginContext.email, otp_token: otp }).unwrap();
            toast({ title: 'OTP verified successfully!' });
            router.push('/dashboard');
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast({ title: 'Invalid OTP. Please try again.', variant: 'destructive' });
        }
    };

    const handleBack = () => {
        setLoginContext({ ...loginContext, stage: 0, email: '' });
        setOtp('');
    };

    return (
        <div className="flex h-2/3 w-full flex-col justify-center gap-4 py-8 md:justify-between">
            <div>
                <BackButton handleOnClickBack={handleBack} />
            </div>
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-2">
                        <h2 className="mt-19 text-2xl font-bold text-gray-800 md:text-2xl">Verification</h2>
                        <h3 className="text-sm text-gray-500">
                            A verification code has been sent to <span className="text-gray-900 underline">{loginContext.email}</span>
                        </h3>
                    </div>
                    <div className="flex w-full flex-col gap-2">
                        <label htmlFor="otp" className="block font-medium text-neutral-800">
                            Verification Code
                        </label>
                        <TextInput id="otp" type="text" placeholder="Enter the verification code" value={otp} onChange={(e) => setOtp(e.target.value)} />
                    </div>
                    <Button className="mt-4" variant={'default'} onClick={handleVerifyOtp}>
                        {isVerifying ? 'Verifying...' : loginContext.isNewUser ? 'Sign Up' : 'Sign In'}
                    </Button>
                </div>

                <div className="flex justify-center gap-1 text-gray-500">
                    <h1>Already have an account? </h1>
                    <div className="cursor-pointer text-primary" onClick={handleBack}>
                        Log in
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginOTPVerificationComponent;
